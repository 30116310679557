.cardImage {
    font-size: 75px;
}

.cardImageInner {
    background-color: #6696f0;
    border-radius: 50px;
    font-size: 50px;
    padding: 8px;
    margin-right: 5px;
    color: white !important;
}

.landingTitle {
    color: #240046;
    font-size: 35px !important;
}

.howTitle {
    font-size: 40px;
    font-weight: bold;
    display: inline-block;
}

.mainBlockLanding {
    padding-top: 200px;
    padding-bottom: 200px;
    padding-left: 20px;
    line-height: 1;
    background-color: #fbfbfb;
    background-image: url('./imgs/background.jpeg');
    background-position: top;
    color: white;
    border-bottom: 1px solid rgb(209, 209, 209);
    overflow: hidden;
    z-index: 1;
}

.logoLandingCont {
    margin-top: -200px;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .logoLandingCont {
        margin-top: 0px;
        margin-left: -25px;
    }

    .mainBlockLanding {
        margin-top: 0px;
    }
  }

.logoLanding {
    height: 700px;
    width: 550px !important;
    z-index: 1;
}
.mainBlockLanding h1 {
    margin-bottom: 25px;
}
.mainBlockLanding b {
    color: #57d5f5;
}

.ctaLanding {
    font-size: 25px;
    z-index: 99;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #530ad1;
}

.secondCTA {
    padding-bottom: 50px;
}
.howBlockLanding {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    background-color: white !important;
}
.howBlockLanding h1 {
    color: white !important;
    margin-bottom: 30px;
}

.pricingBlockLanding h1 {
    margin-bottom: 30px;
}
.pricingBlockLanding {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    background-color: #fbfbfb;
}

.howBlockCol {
    margin-bottom: 24px;
}
.footerLeft {
    text-align: left;
}

.footerRight {
    text-align: right;
    color: white;
}

.footerLink {
    text-decoration: none;
    color: white;
}

.footerLink:hover {
    opacity: 0.7;
    color: #6696f0;
}

.footerBackground {
    background-color: rgb(8, 40, 87);
}

@media only screen and (max-width: 574px) {
    .footerRight {
        text-align: left;
    }
  }
.center-align {
    text-align: center;
}

.usage-selection {
    font-size: 50px;
    padding: 25px;
    margin-left: 5px;
}

.plan-selection {
    margin-bottom: 10px;
}

.small-font {
    font-size: 15px;
    font-weight: bold;
}

.planTitle {
    font-size: 25px;
    font-weight: bolder;
}

.subTextFont {
    font-size: 13px;
}
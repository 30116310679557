.cardInfo:hover {
    background-color: black !important;
    color: white;
    opacity: 0.2;
    cursor: pointer;
}

.del-card-button {
    background-color: white;
    color: red;
    border: none;
    float: right;
    z-index: 99;
}
.del-card-button:hover {
    opacity: 1;;
}
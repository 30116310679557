
.customNav {
    background-color: transparent !important;
    color: black !important;
}
.registerLink {
    font-size: 14px;
    text-decoration: underline;
}

.registerLink:hover {
    cursor: pointer;
}

.legalLink {
    font-size: 12px;
}
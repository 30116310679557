.cta-button {
    margin-bottom: 5px;
}

.contactBtn {
    background-color: white;
    color: black;
    border: none;
}

.badgeForwardTo {
    color: rgba(0, 0, 0, 0.514);
}
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.black-text {
  color: black !important; 
}
body {
  font-family: 'Inter', sans-serif !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.customNav {
    background-color: transparent !important;
    color: black !important;
}
.registerLink {
    font-size: 14px;
    text-decoration: underline;
}

.registerLink:hover {
    cursor: pointer;
}

.legalLink {
    font-size: 12px;
}
.alignOr {
    text-align: center;
}
.cardImage {
    font-size: 75px;
}

.cardImageInner {
    background-color: #6696f0;
    border-radius: 50px;
    font-size: 50px;
    padding: 8px;
    margin-right: 5px;
    color: white !important;
}

.landingTitle {
    color: #240046;
    font-size: 35px !important;
}

.howTitle {
    font-size: 40px;
    font-weight: bold;
    display: inline-block;
}

.mainBlockLanding {
    padding-top: 200px;
    padding-bottom: 200px;
    padding-left: 20px;
    line-height: 1;
    background-color: #fbfbfb;
    background-image: url(/static/media/background.0900c022.jpeg);
    background-position: top;
    color: white;
    border-bottom: 1px solid rgb(209, 209, 209);
    overflow: hidden;
    z-index: 1;
}

.logoLandingCont {
    margin-top: -200px;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .logoLandingCont {
        margin-top: 0px;
        margin-left: -25px;
    }

    .mainBlockLanding {
        margin-top: 0px;
    }
  }

.logoLanding {
    height: 700px;
    width: 550px !important;
    z-index: 1;
}
.mainBlockLanding h1 {
    margin-bottom: 25px;
}
.mainBlockLanding b {
    color: #57d5f5;
}

.ctaLanding {
    font-size: 25px;
    z-index: 99;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #530ad1;
}

.secondCTA {
    padding-bottom: 50px;
}
.howBlockLanding {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    background-color: white !important;
}
.howBlockLanding h1 {
    color: white !important;
    margin-bottom: 30px;
}

.pricingBlockLanding h1 {
    margin-bottom: 30px;
}
.pricingBlockLanding {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    background-color: #fbfbfb;
}

.howBlockCol {
    margin-bottom: 24px;
}
.center-align {
    text-align: center;
}

.usage-selection {
    font-size: 50px;
    padding: 25px;
    margin-left: 5px;
}

.plan-selection {
    margin-bottom: 10px;
}

.small-font {
    font-size: 15px;
    font-weight: bold;
}

.planTitle {
    font-size: 25px;
    font-weight: bolder;
}

.subTextFont {
    font-size: 13px;
}
.cardInfo:hover {
    background-color: black !important;
    color: white;
    opacity: 0.2;
    cursor: pointer;
}

.del-card-button {
    background-color: white;
    color: red;
    border: none;
    float: right;
    z-index: 99;
}
.del-card-button:hover {
    opacity: 1;;
}
.cta-button {
    margin-bottom: 5px;
}

.contactBtn {
    background-color: white;
    color: black;
    border: none;
}

.badgeForwardTo {
    color: rgba(0, 0, 0, 0.514);
}
.footerLeft {
    text-align: left;
}

.footerRight {
    text-align: right;
    color: white;
}

.footerLink {
    text-decoration: none;
    color: white;
}

.footerLink:hover {
    opacity: 0.7;
    color: #6696f0;
}

.footerBackground {
    background-color: rgb(8, 40, 87);
}

@media only screen and (max-width: 574px) {
    .footerRight {
        text-align: left;
    }
  }
